import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Instructions to install TypeScript with Gatsby." title="Gatsby" keywords={['gatsby']} mdxType="SEO" />
    <h1 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h1>
    <p>{`1`}{`.`}{` Install `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/packages/gatsby-plugin-typescript/"
      }}><inlineCode parentName="a">{`gatsby-plugin-typescript`}</inlineCode></a>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install gatsby-plugin-typescript
`}</code></pre>
    <p>{`or`}</p>
    <pre><code parentName="pre" {...{}}>{`yarn add gatsby-plugin-typescript
`}</code></pre>
    <p>{`2`}{`.`}{` Add to array of `}<inlineCode parentName="p">{`plugins`}</inlineCode>{` in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// gatsby-config.js
module.exports = {
  // ...,
  plugins: [...\`gatsby-plugin-typescript\`],
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      